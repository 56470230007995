import { NgStyle, TitleCasePipe } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import { Icon } from '~icons'

import { RemoveUnderscoresPipe } from '../../pipes/remove-underscores.pipe'
import { BoxComponent } from '../../ui/box/box.component'

@Component({
  selector: 'sb-channel-icons',
  template: `
    @if (channelTypes?.length > 0) {
      <sb-box direction="row" gap="6px">
        @for (channelType of channelTypes; track channelType) {
          <mat-icon
            class="icon"
            [ngStyle]="{ width: iconSize, height: iconSize }"
            [svgIcon]="channelIconsMap[channelType]"
            [title]="channelType | titlecase | removeUnderscores"
          />
        }
      </sb-box>
    }
  `,
  styles: [],
  standalone: true,
  imports: [BoxComponent, MatIconModule, NgStyle, TitleCasePipe, RemoveUnderscoresPipe],
})
export class ChannelIconsComponent {
  @Input() channelTypes: string[] = []
  @Input() iconSize: string = '18px'

  channelIconsMap = {
    facebook: Icon.Facebook,
    'facebook-ad-account': Icon.Campaign,
    'facebook-page': Icon.Facebook,
    instagram: Icon.Instagram,
    'instagram-account': Icon.Instagram,
    'linkedin-organization': Icon.Linkedin,
    // 'audience_network': Icon.AudienceNetwork,
    // 'messenger': Icon.Messenger,
    // 'whatsapp': Icon.Whatsapp,
  }
}
